<template>
  <div>
    <el-upload
      class="upload-demo"
      :value="value"
      :show-file-list="false"
      :file-list="value"
      :headers="imgData.header"
      :data="reqData"
      :action="imgData.url"
      :before-upload="beforeUpload"
      :on-progress="onProgress"
      :on-success="onSuccess"
      :on-exceed="onExceed"
      :limit="isLogo ? 1 : 15"
    >
      <!-- <i class="el-icon-plus" /> -->
      <el-button size="small" plain type="primary">点击上传</el-button>
      <div v-for="(val, i) in tip" slot="tip" class="el-upload__tip">{{ val }}</div>
    </el-upload>
    <p v-for="(item, i) in value" :key="i" class="file">
      <a :href="item.file_url" target="_blank">{{ item.file_name }}</a>
      <span class="red pointer" @click="onRemove(i)">删除</span>
    </p>
    <el-progress v-if="progressFlag" :percentage="loadProgress" class="file" />
  </div>
</template>

<script>
import cookie from '@/utils/cookie'
import { MessageBox } from 'element-ui'

export default {
  name: 'File', //文件上传
  props: {
    value: {
      type: Array,
      default: ''
    },
    tip: {
      type: Array,
      default: null
    }
  },
  data() {
    return {
      isLogo: this.tip[0] === '公司商标 Logo (商户后台用、客户付款时会展示此logo)',
      imgData: {
        header: { Authorization: cookie.getToken() }, // 图片上传带上token
        url: process.env.VUE_APP_BASEURL + 'schedule/file' // 上传资料的路径
      },
      reqData: { name: '' },
      loadProgress: 0, // 动态显示进度条
      progressFlag: false // 关闭进度条
    }
  },
  methods: {
    // 进度条
    onProgress(event) {
      this.progressFlag = true // 显示进度条
      this.loadProgress = parseInt(event.percent) // 动态获取文件上传进度
      if (this.loadProgress >= 100) {
        this.loadProgress = 100
        setTimeout(() => {
          this.progressFlag = false
        }, 500) // 一秒后关闭进度条
      }
    },
    // 文件上传之前
    beforeUpload(file) {
      // const isLt2M = file.size / 1000 / 1000 < 6
      // if (!isLt2M) {
      //   this.$message('上传图片大小不能超过 6MB!')
      //   return isLt2M
      // }
      this.reqData.name = file.name
      if (this.isLogo) this.$set(this.reqData, 'is_public', true)
    },
    // 上传成功
    onSuccess(res, file, fileList) {
      if (res.code === 200) {
        this.value.push(res.data)
        this.$emit('input', this.value)
      } else {
        this.$message(res.error)
      }
    },
    // 删除
    onRemove(i) {
      this.value.splice(i, 1)
      this.$emit('input', this.value)
    },
    // 上传多了
    onExceed() {
      return MessageBox.confirm(`最多上传${this.isLogo ? 1 : 15}张`)
    },
    // 处理给父组件的数据
    setData() {
      const arr = JSON.parse(JSON.stringify(this.value))
      // 如果是logo：logo_file_url，只能传1个logo
      if (this.isLogo) {
        this.$emit('input', arr[0].file_url)
      } else {
        arr.forEach((item) => delete item.file_url)
        this.$emit('input', JSON.stringify(arr))
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.el-upload__tip {
  line-height: 1;
}
.red {
  margin-left: 20px;
}
.file {
  width: 400px;
  display: flex;
  justify-content: space-between;
  line-height: 1;
  padding: 8px 10px;
  &:hover {
    background: #f5f7fa;
  }
  &:first-of-type {
    margin-top: 10px;
  }
  // &:last-of-type{
  //   // transition: 0.5s;
  //   // animation-delay: .3s;
  // }
}
</style>

