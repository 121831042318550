<template>
  <div>
    <ul class="steps">
      <li :class="{ now: this.step === 'Information' }">
        填写商户资料
        <p class="left-arrow" />
      </li>
      <li :class="{ now: this.step === 'Supplement' }">
        补充文档
        <p class="left-arrow" />
      </li>
      <li :class="{ now: this.step === 'Submit' }">完成提交</li>
    </ul>
    <Information v-show="this.step === 'Information'" ref="Information" />
    <Supplement v-show="this.step === 'Supplement'" ref="Supplement" />
    <Submit v-show="this.step === 'Submit'" />
  </div>
</template>

<script>
import { scheduleMerchant } from '@/api/api'
import Information from './information'
import Supplement from './supplement'
import Submit from './submit'

export default {
  name: 'dataReview', //资料审核
  components: { Information, Supplement, Submit },
  data() {
    return {
      // routeName: this.$route.name,
      step: 'Information',
      form: {
        // business_country_code: '',
        // company_name: '',
        // register_certificate: '',
        // register_country: '',
        // merchant_short_name: '',
        // business_call: '',
        // amount_average: '',
        // service_phone: '',
        // merchant_describe: '',
        // merchant_remark: '',
        // merchant_offline: ['offline'],
        // contact_name: '',
        // contact_email: '',
        // contact_phone: '',
        // wx_channel: [], //微信支付通道
        // wx_industry_list: [], //行业大类，行业细类
        // wx_currency_list: { online: [], offline: [] }, //微信线上线下结算币种
        // ali_channel: [], //支付宝支付通道
        // ali_industry_list: [], //商户大类，商户细类，通用范围
        // ali_currency_list: { online: [], offline: [] }, //支付宝线上线下结算币种
        // merchant_url: '',
        // merchant_store_address: '',
        // merchant_service_begin_time: '',
        // merchant_service_end_time: '',
        // merchant_store_pic: []
      },
      bankAccounts: [],
      fileForm: {
        // certificate_file: '',
        // director_file: '',
        // shareholder_file: '',
        // apply_in_file: '',
        // logo_file_url: ''
      }
    }
  },
  // watch: {
  //   $route(to) {
  //     this.routeName = to.name
  //   }
  // },
  created() {
    const merchant_no = this.$route.query.merchant_no
    merchant_no && this.scheduleMerchant(merchant_no)
  },
  methods: {
    scheduleMerchant(merchant_no) {
      scheduleMerchant({ merchant_no }).then((res) => {
        if (res.code === 200) {
          this.bankAccounts = res.data.bank_accounts
          const [obj, file] = [res.data.merchant_basic, res.data.file]
          Object.keys(obj).forEach((key) => {
            let val = obj[key]
            if (val) {
              if (key === 'merchant_offline') {
                val = val === 'MIX' ? ['online', 'offline'] : [val.toLowerCase()]
              } else if (key === 'wx_channel' || key === 'ali_channel') {
                let [channel, pay_channel] = [JSON.parse(obj[key]), []]
                channel.pay_channel.forEach((item) => {
                  pay_channel.push(`${item.channel}-${item.channel_type}`)
                })
                channel.pay_channel = pay_channel
                val = { ...channel }
              }
              obj[key] = val
            } else {
              delete obj[key]
            }
          })
          this.form = { ...obj }
          const { contract, supplement, merchant_store_pic, ...fileForm } = file
          this.fileForm = {
            ...fileForm,
            logo_file_url: obj.logo_file_url ? [{ file_name: 'logo', file_url: obj.logo_file_url }] : []
          }
          this.form.merchant_store_pic = merchant_store_pic

          // 回填数据
          const [childInformation, childSupplement] = [this.$refs.Information, this.$refs.Supplement]
          childInformation.form = Object.assign({}, childInformation.form, this.form)
          childInformation.tableData = [...this.bankAccounts]
          childSupplement.form = Object.assign({}, childSupplement.form, this.fileForm)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.steps {
  width: 100%;
  height: 46px;
  line-height: 46px;
  display: flex;
  & > li {
    width: 33.3%;
    text-align: center;
    // border: 1px solid red;
    position: relative;
    background: #f5f7fa;
    & > .left-arrow {
      position: absolute;
      top: 7px;
      right: -16px;
      width: 32px;
      height: 32px;
      border-right: 1px solid #dcdfe6;
      border-bottom: 1px solid #dcdfe6;
      transform: rotate(-45deg);
      background: #f5f7fa;
      z-index: 10;
    }
  }
  .now {
    background: #409eff;
    color: #fff;
    & > .left-arrow {
      background: #409eff;
      border: none;
    }
  }
}
</style>

