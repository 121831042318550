<template>
  <div>
    <el-form :model="form" :rules="rules" ref="ruleForm" class="form" label-position="top">
      <p>商户基本资料</p>
      <!-- 我方签约主体 -->
      <p class="subtitle">我方签约主体</p>
      <el-form-item label="我方签约主体" prop="business_country_code">
        <el-select v-model="form.business_country_code" placeholder="请选择我方签约主体" @change="countryCodeChange">
          <el-option label="香港华融" value="HKG" />
          <el-option label="TransferEasy NZ" value="NZL" />
          <el-option label="TransferEasy AU" value="AUS" />
          <el-option label="TransferEasy USA" value="USA" />
        </el-select>
      </el-form-item>
      <el-divider />
      <!-- 商户基本资料 -->
      <p class="subtitle">商户基本资料</p>
      <el-form-item label="公司主体名称" prop="company_name">
        <el-input v-model="form.company_name" placeholder="请输入公司主体名称" />
      </el-form-item>
      <!-- 美国 /非美国-->
      <el-form-item v-if="form.business_country_code === 'USA'" label="税务登记号">
        <el-input v-model="form.register_certificate" placeholder="请输入税务登记号" />
      </el-form-item>
      <el-form-item v-else label="公司注册证书编号Certificate of Incorporation (CI)">
        <el-input v-model="form.register_certificate" placeholder="如无限公司，请提供商业登记证 (BR) 号码" />
      </el-form-item>
      <el-form-item label="注册国家/地区" prop="register_country">
        <Country v-model="form.register_country" />
      </el-form-item>
      <el-form-item label="商户简称（*提交后将无法再修改）" prop="merchant_short_name">
        <el-input v-model="form.merchant_short_name" placeholder="请输入商户简称" />
      </el-form-item>
      <el-form-item label="商户名称">
        <el-input v-model="form.business_call" placeholder="请输入商户名称" />
      </el-form-item>
      <el-form-item label="单笔平均交易金额">
        <el-input v-model="form.amount_average" placeholder="请输入单笔平均交易金额（单笔最大额度为100，000）" />
      </el-form-item>
      <el-form-item label="客服电话">
        <el-input v-model="form.service_phone" placeholder="请输入客服电话" />
      </el-form-item>
      <el-form-item label="商户简介">
        <el-input
          type="textarea"
          placeholder="请描述经营业务范围、商业模式和具体商品种类"
          v-model="form.merchant_describe"
        />
      </el-form-item>
      <el-form-item label="商户备注（可填写：支付宝费率%，微信费率%）">
        <el-input v-model="form.merchant_remark" placeholder="请输入商户备注" />
      </el-form-item>
      <el-form-item label="企业主要股东ID">
        <el-input v-model="form.juridical_man_code" placeholder="请输入企业主要股东ID" />
      </el-form-item>
      <p>
        <el-form-item label="商户类别">
          <el-checkbox-group v-model="form.merchant_offline">
            <!-- offline线下，online线上 mix 两者都有 -->
            <el-checkbox label="online">线上商户</el-checkbox>
            <el-checkbox label="offline">线下商户</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </p>
      <el-form-item label="企业注册地址">
        <el-input v-model="form.company_register_place" placeholder="请输入企业注册地址" />
      </el-form-item>
      <!-- 商户网址：有线上商户 -->
      <template v-if="form.merchant_offline.find((val) => val === 'online')">
        <el-form-item label="商户网址（如有）">
          <el-input v-model="form.merchant_url" placeholder="请输入商户网址" />
        </el-form-item>
      </template>
      <!-- 这几个：有线下商户  -->
      <template v-if="form.merchant_offline.find((val) => val === 'offline')">
        <el-form-item label="门店地址">
          <el-input v-model="form.merchant_store_address" placeholder="请输入门店地址" />
        </el-form-item>
        <el-form-item label="营业开始时间" class="business-hours">
          <el-time-picker
            value-format="HH:mm:ss"
            format="HH:mm:ss"
            v-model="form.merchant_service_begin_time"
            placeholder="请选择营业开始时间"
          />
        </el-form-item>
        <el-form-item label="营业终止时间" class="business-hours">
          <el-time-picker
            value-format="HH:mm:ss"
            format="HH:mm:ss"
            v-model="form.merchant_service_end_time"
            placeholder="请选择营业开始时间"
          />
        </el-form-item>
        <el-form-item label="门店照片" label-position="left">
          <File
            :tip="['请提供至少3张(含) 以上商店照片，包括商店招牌，环境布局，陈列等']"
            v-model="form.merchant_store_pic"
          />
        </el-form-item>
      </template>
      <el-divider />
      <!-- 商户联络资料 -->
      <p class="subtitle">商户联络资料</p>
      <el-form-item label="联络人姓名">
        <el-input v-model="form.contact_name" placeholder="请输入联络人姓名" />
      </el-form-item>
      <el-form-item label="联络人邮箱">
        <el-input v-model="form.contact_email" placeholder="请输入联络人邮箱" />
      </el-form-item>
      <el-form-item label="联络人电话">
        <el-input v-model="form.contact_phone" placeholder="请输入联络人电话" />
      </el-form-item>
      <el-divider />
      <!-- 支付通道:微信:任何币种都有，支付宝线上:美国USA没有，支付宝线下:美国USA&新西兰NZL没有————————————————————————————————————————————-->
      <p class="subtitle">支付通道</p>
      <el-form-item label="选择支付渠道（可多选）">
        <el-checkbox-group v-model="form.wx_channel.pay_channel" class="inline">
          <el-checkbox label="WECHATPAY-1">微信线上</el-checkbox>
          <el-checkbox label="WECHATPAY-2">微信线下</el-checkbox>
        </el-checkbox-group>
        <el-checkbox-group v-model="form.ali_channel.pay_channel" class="inline">
          <el-checkbox label="ALIPAY-1">支付宝线上</el-checkbox>
          <el-checkbox label="ALIPAY-2" v-if="form.business_country_code !== 'NZL'">支付宝线下</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-divider />
      <!-- 微信渠道 (支付通道:有微信线上/微信线下),微信线上结算币种:有微信线上,微信线下结算币种:有微信线下————————————————————————————————————————————————————————————————————————————————————————-->
      <template v-if="form.wx_channel.pay_channel.length > 0">
        <p class="subtitle">微信渠道</p>
        <el-form-item label="行业类别">
          <el-select v-model="form.wx_channel.industry_class" filterable placeholder="请选择行业类别">
            <el-option v-for="(val, key) in wxIndustry1" :label="val" :value="val" :key="key" />
          </el-select>
        </el-form-item>
        <el-form-item label="MCC Code">
          <el-select
            v-model="form.wx_channel.industry_mince"
            filterable
            placeholder="请选择MCC Code"
            @change="getWxMcc"
          >
            <el-option v-for="(val, i) in wxIndustry2" :label="`${val.mcc}-${val.zh}`" :value="val.zh" :key="i" />
          </el-select>
        </el-form-item>
        <el-form-item label="微信线上结算币种" v-if="form.wx_channel.pay_channel.find((val) => val === 'WECHATPAY-1')">
          <el-checkbox-group v-model="form.wx_channel.wx_allow_currency">
            <el-checkbox v-for="item in wxCurList" :label="item" :key="item" />
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="微信线下结算币种" v-if="form.wx_channel.pay_channel.find((val) => val === 'WECHATPAY-2')">
          <el-checkbox-group v-model="form.wx_channel.wxfl_allow_currency">
            <el-checkbox v-for="item in wxCurList" :label="item" :key="item" />
          </el-checkbox-group>
        </el-form-item>
        <el-divider />
      </template>
      <!-- 支付宝渠道 (支付通道:有支付宝线上/支付宝线下),支付宝线上结算币种:有支付宝线上,支付宝线下结算币种:有支付宝线下————————————————————————————————————————————————————————————————————————————————————————-->
      <template v-if="form.ali_channel.pay_channel.length > 0">
        <p class="subtitle">支付宝渠道</p>
        <el-form-item label="商户大类">
          <el-select
            v-model="form.ali_channel.industry_class"
            filterable
            placeholder="请选择商户大类"
            @change="
              form.ali_channel.industry_mince = ''
              form.ali_channel.industry_range = ''
            "
          >
            <el-option v-for="(val, key) in aliIndustry1" :label="key" :value="key" :key="key" />
          </el-select>
        </el-form-item>
        <el-form-item label="商户细类">
          <el-select
            v-model="form.ali_channel.industry_mince"
            filterable
            placeholder="请选择商户细类"
            @change="form.ali_channel.industry_range = ''"
          >
            <el-option v-for="(val, key) in aliIndustry2" :label="key" :value="key" :key="key" />
          </el-select>
        </el-form-item>
        <el-form-item label="通用范围">
          <el-select
            v-model="form.ali_channel.industry_range"
            filterable
            placeholder="请选择通用范围"
            @change="(val) => (form.mcc = aliIndustry3[val])"
          >
            <el-option v-for="(val, key) in aliIndustry3" :label="`${val} - ${key}`" :value="key" :key="key" />
          </el-select>
        </el-form-item>
        <el-form-item label="支付宝线上结算币种" v-if="form.ali_channel.pay_channel.find((val) => val === 'ALIPAY-1')">
          <el-checkbox-group v-model="form.ali_channel.ali_allow_currency">
            <el-checkbox v-for="item in wxCurList" :label="item" :key="item" />
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="支付宝线下结算币种" v-if="form.ali_channel.pay_channel.find((val) => val === 'ALIPAY-2')">
          <el-checkbox-group v-model="form.ali_channel.alifl_allow_currency">
            <el-checkbox v-for="item in aliCurOffline" :label="item" :key="item" />
          </el-checkbox-group>
        </el-form-item>
        <el-divider />
      </template>
    </el-form>
    <!-- 商户提现账户:注册国家填了 && (2个都填了 || 有merchant_no) 才可以添加 ————————————————————————————————————————————————————————————————————————————————————————-->
    <div class="subtitle">
      商户提现账户
      <p>
        <el-button :disabled="disabled" type="primary" @click="editCart">添加银行账户</el-button>
      </p>
    </div>
    <el-table :data="tableData" border>
      <el-table-column label="银行账号">
        <template slot-scope="{ row }">{{ row.bank_account_number || '-' }}</template>
      </el-table-column>
      <el-table-column label="账户名">
        <template slot-scope="{ row }">{{ row.bank_account_name || '-' }}</template>
      </el-table-column>
      <el-table-column label="账号所属国家或地区">
        <template slot-scope="{ row }">{{ row.country || '-' }}</template>
      </el-table-column>
      <el-table-column label="开户行地址">
        <template slot-scope="{ row }">{{ row.bank_address || '-' }}</template>
      </el-table-column>
      <el-table-column label="开户银行">
        <template slot-scope="{ row }">{{ row.bank_name || '-' }}</template>
      </el-table-column>
      <el-table-column label="Swift Code">
        <template slot-scope="{ row }">{{ row.swift_code || '-' }}</template>
      </el-table-column>
      <el-table-column label="Routing No.">
        <template slot-scope="{ row }">{{ row.routing_number || '-' }}</template>
      </el-table-column>
      <el-table-column label="接收币种">
        <template slot-scope="{ row }">{{ row.currency || '-' }}</template>
      </el-table-column>
      <el-table-column label="Bank Code">
        <template slot-scope="{ row }">{{ row.bank_code || '-' }}</template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <span class="blue pointer" @click="editCart('edit', scope.row, scope.$index)">修改</span>&nbsp;
          <span class="red pointer" @click="scheduleCardDelete(scope.row.no || scope.row.card_no, scope.$index)"
            >删除</span
          >
        </template>
      </el-table-column>
    </el-table>
    <p class="operation">
      <el-button type="success" @click="scheduleIn('ruleForm', 'draft')">保存草稿箱</el-button>
      <el-button type="primary" @click="scheduleIn('ruleForm', 'save')">保存并下一步</el-button>
    </p>
    <el-dialog
      title="商户提现账户"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
      @close="$refs['cardForm'].resetFields()"
    >
      <el-form :model="cardForm" ref="cardForm" :rules="cardRules" label-width="120px">
        <el-form-item label="所属国家和地区" prop="country">
          <Country v-model="form.register_country" disabled />
        </el-form-item>
        <el-form-item label="银行账户名称" prop="bank_account_name" required>
          <el-input v-model="cardForm.bank_account_name" placeholder="请输入银行账户名称" />
        </el-form-item>
        <el-form-item label="银行账号" prop="bank_account_number" required>
          <el-input v-model="cardForm.bank_account_number" placeholder="请输入银行账号" />
        </el-form-item>
        <el-form-item label="银行名称" prop="bank_name" required>
          <el-input v-model="cardForm.bank_name" placeholder="请输入银行名称" />
        </el-form-item>
        <el-form-item label="开户分行名称" prop="branch_name">
          <el-input v-model="cardForm.branch_name" placeholder="请输入开户分行名称" />
        </el-form-item>
        <el-form-item label="开户行地址" prop="bank_address" required>
          <el-input v-model="cardForm.bank_address" placeholder="请输入开户行地址" />
        </el-form-item>
        <el-form-item label="币种" prop="currency">
          <el-select v-model="cardForm.currency" multiple placeholder="接收币种">
            <el-option label="AUD" value="AUD" />
            <el-option label="CAD" value="CAD" />
            <el-option label="EUR" value="EUR" />
            <el-option label="GBP" value="GBP" />
            <el-option label="HKD" value="HKD" />
            <el-option label="JPY" value="JPY" />
            <el-option label="NZD" value="NZD" />
            <el-option label="SGD" value="SGD" />
            <el-option label="USD" value="USD" />
            <el-option label="MOP" value="MOP" />
          </el-select>
        </el-form-item>
        <el-form-item label="Routing No." prop="routing_number" v-if="form.business_country_code === 'USA'">
          <el-input v-model="cardForm.routing_number" placeholder="请输入Routing No." />
        </el-form-item>
        <el-form-item label="Swift Code" prop="swift_code" v-else>
          <el-input v-model="cardForm.swift_code" placeholder="请输入Swift Code" />
        </el-form-item>
        <el-form-item label="Bank Code" prop="bank_code" :required="form.register_country === 'HKG' ? true : false">
          <el-input v-model="cardForm.bank_code" placeholder="Bank Code" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="okCard('cardForm')">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { scheduleInPost, scheduleInPut, scheduleCardPost, scheduleCardPut, scheduleCardDelete } from '@/api/api'
import mcc from '@/utils/mcc.json'
import Country from '@/components/country'
import File from '@/components/file'

export default {
  name: 'Information', //商户进件，请填写商户资料
  components: { Country, File },
  data() {
    const bank_account_name = (rule, value, callback) => {
      if (value) {
        const en = "^[a-zA-Z0-9/-?:().,'+-/? ]+$" // 纯英文
        const reg = new RegExp(en)
        if (!reg.test(value)) {
          callback(new Error('银行账户名称必须为英文'))
        } else {
          callback()
        }
      } else {
        callback('请输入银行账户名称')
      }
    }
    const bank_account_number = (rule, value, callback) => {
      if (value) {
        const en = '^[a-zA-Z0-9]*$' // 纯英文
        const reg = new RegExp(en)
        if (!reg.test(value)) {
          callback(new Error('银行账号只允许字母和数字'))
        } else {
          callback()
        }
      } else {
        callback('请输入银行账号')
      }
    }
    const bank_name = (rule, value, callback) => {
      if (value) {
        const en = "^[a-zA-Z0-9/-?:().,'+-/? ]+$" // 纯英文
        const reg = new RegExp(en)
        if (!reg.test(value)) {
          callback(new Error('银行名称必须为英文'))
        } else {
          callback()
        }
      } else {
        callback('请输入银行名称')
      }
    }
    const branch_name = (rule, value, callback) => {
      if (value) {
        const en = "^[a-zA-Z0-9/-?:&()'.,;+-/? ]+$" // 纯英文
        const reg = new RegExp(en)
        if (!reg.test(value)) {
          callback(new Error('开户分行名称必须为英文'))
        } else {
          callback()
        }
      } else {
        callback()
      }
    }
    const bank_address = (rule, value, callback) => {
      if (value) {
        const en = "^[a-zA-Z0-9/-?:().,'+-/? ]+$" // 纯英文
        const reg = new RegExp(en)
        if (!reg.test(value)) {
          callback(new Error('开户行地址必须为英文'))
        } else if (value.length > 70) {
          callback(new Error('开户行地址不可超过70个字符'))
        } else {
          callback()
        }
      } else {
        callback('请输入开户行地址')
      }
    }
    const bank_code = (rule, value, callback) => {
      if (value) {
        const numReg = '^[0-9]*$' // 必须为数字
        const reg = new RegExp(numReg)
        if (reg.test(value)) {
          if (value.length === 3) {
            callback()
          } else {
            callback('请输入3位数字')
          }
        } else {
          callback('请输入数字')
        }
      } else {
        this.form.register_country === 'HKG' ? callback('请输入3位数字') : callback()
      }
    }
    const swift_code = (rule, value, callback) => {
      if (value) {
        const numLetterReg = '^[0-9a-zA-Z]*$' // 数字和字母的组合
        const reg = new RegExp(numLetterReg)
        if (reg.test(value)) {
          if (value.length !== 8 && value.length !== 11) {
            callback(new Error('请输入8/11位数字或字母'))
          } else {
            callback()
          }
        } else {
          callback('请输入数字或字母')
        }
      } else {
        callback('请输入8/11位数字或字母')
      }
    }
    return {
      aliIndustry1: mcc.ali,
      // aliIndustry2: {},
      // aliIndustry3: {},
      wxIndustry1: mcc.wx,
      wxIndustry2: mcc.wx_mcc,
      wxCurList: ['HKD', 'NZD', 'AUD', 'EUR', 'JPY', 'GBP', 'SGD', 'USD', 'CAD'],
      aliCurOffline: ['HKD'],
      form: {
        merchant_offline: [],
        merchant_store_pic: [],
        wx_channel: {
          industry_class: '',
          industry_mince: '',
          pay_channel: [],
          wx_allow_currency: [],
          wxfl_allow_currency: []
        },
        ali_channel: {
          ali_allow_currency: [],
          alifl_allow_currency: [],
          industry_class: '',
          industry_mince: '',
          industry_range: '',
          pay_channel: []
        }
      },
      rules: {
        business_country_code: [{ required: true, message: '请选择我方签约主体', trigger: 'change' }],
        company_name: [{ required: true, message: '请输入公司主体名称', trigger: 'blur' }],
        register_country: [{ required: true, message: '请输入选择注册国家/地区', trigger: 'change' }],
        merchant_short_name: [{ required: true, message: '请输入商户简称', trigger: 'blur' }]
      },
      tableData: [],
      dialogFormVisible: false,
      type: 'add',
      index: 0,
      cardForm: {
        bank_account_name: '',
        bank_account_number: '',
        bank_name: '',
        bank_address: '',
        branch_name: '',
        currency: [],
        swift_code: '',
        bank_code: '',
        routing_number: ''
      },
      cardRules: {
        bank_account_name: [{ validator: bank_account_name, trigger: ['blur', 'change'] }],
        bank_account_number: [{ validator: bank_account_number, trigger: ['blur', 'change'] }],
        bank_name: [{ validator: bank_name, trigger: ['blur', 'change'] }],
        branch_name: [{ validator: branch_name, trigger: ['blur', 'change'] }],
        bank_address: [{ validator: bank_address, trigger: ['blur', 'change'] }],
        currency: [{ required: true, message: '请选择币种', trigger: 'change' }],
        swift_code: [{ required: true, validator: swift_code, trigger: 'blur' }],
        routing_number: [{ min: 9, max: 9, message: '请填写9位routing_number', trigger: 'blur' }],
        bank_code: [{ validator: bank_code, trigger: ['change', 'blur'] }]
      }
    }
  },
  computed: {
    // 添加银行卡按钮是否可点击：注册国家填了 && (3个都填了 || 有merchant_no) 才可以添加,,,,3个：我方签约主体、公司主体名称、商户简称
    disabled() {
      const { business_country_code, company_name, merchant_short_name, register_country } = this.form
      const [merchant_no] = [this.$route.query.merchant_no]
      let flag = true
      if (register_country) {
        if (merchant_no) return (flag = false)
        flag = !business_country_code || !company_name || !merchant_short_name
      }
      return flag
    },
    // 微信:行业细类的下拉框
    // wxIndustry2() {
    //   return this.wxIndustry1[this.form.wx_channel.industry_class] || []
    // },
    // 支付宝:商户细类的下拉框
    aliIndustry2() {
      return this.aliIndustry1[this.form.ali_channel.industry_class] || {}
    },
    // 支付宝:通用范围的下拉框
    aliIndustry3() {
      return this.aliIndustry2[this.form.ali_channel.industry_mince] || {}
    }
  },
  watch: {
    'form.wx_channel.pay_channel': {
      handler: function (newVal) {
        if (newVal.length === 0) return false
        const wx_allow_currency = newVal.find((val) => val === 'WECHATPAY-1')
        const wxfl_allow_currency = newVal.find((val) => val === 'WECHATPAY-2')
        if (!wx_allow_currency) this.form.wx_channel.wx_allow_currency = []
        if (!wxfl_allow_currency) this.form.wx_channel.wxfl_allow_currency = []
      },
      deep: true //true 深度监听
    },
    'form.ali_channel.pay_channel': {
      handler: function (newVal) {
        if (newVal.length === 0) return delete this.form.mcc
        const ali_allow_currency = newVal.find((val) => val === 'ALIPAY-1')
        const alifl_allow_currency = newVal.find((val) => val === 'ALIPAY-2')
        if (!ali_allow_currency) this.form.ali_channel.ali_allow_currency = []
        if (!alifl_allow_currency) this.form.ali_channel.alifl_allow_currency = []
      },
      deep: true //true 深度监听
    }
  },
  methods: {
    // 获取微信的mcc
    getWxMcc(zh) {
      const re = this.wxIndustry2.find((val) => val.zh === zh)
      this.form.wx_mcc = re.mcc
    },
    // 我方签约主体变化后
    countryCodeChange(val) {
      let wxCurList
      if (val === 'HKG') {
        wxCurList = ['HKD', 'NZD', 'AUD', 'EUR', 'JPY', 'GBP', 'SGD', 'USD', 'CAD']
      } else if (val === 'NZL') {
        wxCurList = ['NZD']
      } else if (val === 'AUS') {
        wxCurList = ['AUD']
      } else if (val === 'USA') {
        wxCurList = ['USD']
      }
      this.wxCurList = [...wxCurList]
      this.aliCurOffline = val === 'HKG' ? ['HKD'] : [...wxCurList]
    },
    // 保存并下一步
    scheduleIn(formName, type) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const [merchant_no, obj] = [this.$route.query.merchant_no, this.setData({ ...this.form })]
          const callback = (no) => {
            if (type === 'draft') {
              this.$router.push({ name: 'Drafts' })
            } else {
              if (!merchant_no) this.$router.push({ name: 'DataReview', query: { merchant_no: no } })
              this.$parent.step = 'Supplement'
            }
          }
          merchant_no
            ? this.scheduleInPut({ merchant_no, ...obj }, callback)
            : this.scheduleInPost({ ...obj }, callback)
        } else {
          return false
        }
      })
    },
    // 有merchant_no
    scheduleInPut(params, callback) {
      scheduleInPut(params).then((res) => {
        if (res.code === 200) {
          callback(this.$route.query.merchant_no)
        }
      })
    },
    // 没有merchant_no
    scheduleInPost(params, callback) {
      scheduleInPost(params).then((res) => {
        if (res.code === 200) {
          callback(res.data.no)
        }
      })
    },
    // 添加，修改银行卡按钮
    editCart(type, row, i) {
      this.type = type
      this.dialogFormVisible = true
      if (type === 'edit') {
        this.index = i
        const { property, status, created_time, branch_code, no, card_no, ...cardForm } = row
        this.cardForm = { ...cardForm, card_no: no || card_no }
        this.cardForm.currency = cardForm.currency.split(',')
        this.cardForm.bank_account_number = ''
      } else {
        // 清空数据
        this.cardForm = {
          bank_account_name: '',
          bank_account_number: '',
          bank_name: '',
          bank_address: '',
          branch_name: '',
          currency: [],
          swift_code: '',
          bank_code: ''
        }
        this.$nextTick(() => {
          this.$refs['cardForm'].clearValidate()
        })
      }
    },
    // 银行卡，确定按钮（添加或修改）
    okCard(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const merchant_no = this.$route.query.merchant_no
          const params = { ...this.cardForm }
          params.currency = params.currency.join(',')
          params.country = this.form.register_country
          if (this.type === 'edit') {
            // 修改银行卡
            scheduleCardPut({ ...params, merchant_no }).then((res) => {
              if (res.code === 200) {
                this.dialogFormVisible = false
                this.$set(this.tableData, this.index, Object.assign({}, this.tableData, res.data))
              }
            })
          } else {
            // 添加银行卡 // 回调函数
            const callback = (no) => {
              scheduleCardPost({ ...params, merchant_no: no }).then((res) => {
                this.dialogFormVisible = false
                this.tableData.push(res.data)
                if (!merchant_no) this.$router.push({ name: 'DataReview', query: { merchant_no: no } })
              })
            }
            // 如果有merchant_no直接保存银行卡,如果没有,先调scheduleInPost，生成一个merchant_no
            merchant_no ? callback(merchant_no) : this.scheduleInPost(this.setData({ ...this.form }), callback)
          }
        } else {
          return false
        }
      })
    },
    // 删除银行卡
    scheduleCardDelete(no, i) {
      scheduleCardDelete({ no }).then((res) => {
        if (res.code === 200) {
          this.tableData.splice(i, 1)
        }
      })
    },
    setData(obj) {
      obj = JSON.parse(JSON.stringify(obj))
      Object.keys(obj).forEach((key) => {
        let val = obj[key]
        if (val) {
          if (
            key === 'business_country_code' ||
            key === 'company_name' ||
            key === 'register_certificate' ||
            key === 'register_country' ||
            key === 'merchant_short_name' ||
            key === 'business_call' ||
            key === 'amount_average' ||
            key === 'service_phone' ||
            key === 'merchant_describe' ||
            key === 'merchant_remark' ||
            key === 'merchant_url' ||
            key === 'merchant_store_address' ||
            key === 'merchant_service_begin_time' ||
            key === 'merchant_service_end_time' ||
            key === 'contact_name' ||
            key === 'contact_email' ||
            key === 'contact_phone' ||
            key === 'mcc' ||
            key === 'wx_mcc' ||
            key === 'juridical_man_code' ||
            key === 'company_register_place'
            // merchant_store_pic,wx_channel,ali_channel
          ) {
            val = val
          } else if (key === 'wx_channel' || key === 'ali_channel') {
            if (val.pay_channel.length === 0) return delete obj[key]
            let pay_channel = []
            val.pay_channel.forEach((item) => {
              pay_channel.push({
                channel: item.split('-')[0],
                channel_type: Number(item.split('-')[1])
                // region: obj.business_country_code.slice(0, 2)
              })
            })
            val.pay_channel = pay_channel
            obj[key] = JSON.stringify(val)
          } else if (key === 'merchant_offline') {
            if (val.length === 0) return delete obj[key]
            obj[key] = val.length === 1 ? val[0] : 'mix'
          } else if (key === 'merchant_store_pic') {
            if (val.length === 0) return delete obj[key]
            val.forEach((item) => delete item.file_url)
            obj[key] = JSON.stringify(val)
          } else {
            delete obj[key]
          }
        } else {
          delete obj[key]
        }
      })
      return obj
    }
  }
}
</script>

<style lang="scss" scoped>
.form {
  margin-top: 20px;
  border: 1px solid #f0f2f5;
  border-bottom: 1px solid #f0f2f5;
  & > p:nth-of-type(1) {
    height: 46px;
    line-height: 46px;
    background: #f5f7fa;
    margin-bottom: 20px;
    padding-left: 20px;
  }
  .el-form-item {
    padding: 0 50px;
    // width: 50%;
    // display: inline-block;
    // vertical-align: top;
  }
  .subtitle {
    margin-left: 30px;
  }

  .el-divider {
    background-color: #f0f2f5;
  }
  // 营业开始时间，营业终止时间
  .business-hours {
    display: inline-block;
    width: 50%;
  }
  // 支付通道
  .inline {
    display: inline-block;
  }
  .inline:nth-of-type(2) {
    margin-left: 30px;
  }
}
.el-select {
  width: 100%;
}
.operation {
  text-align: center;
}
</style>
<style lang="scss">
.form {
  .el-form-item__label {
    padding: 0;
  }
}
</style>

