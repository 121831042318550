<template>
  <el-select :value="value" filterable placeholder="请选择注册国家/地区" :disabled="disabled" @input="change($event)">
    <!-- <el-option v-for="(zh,en) in allCountry" :key="en" :label="language==='zh'?zh:en" :value="en" /> -->
    <el-option v-for="(zh, en) in allCountry" :key="en" :label="`${zh}-${en}`" :value="en" />
  </el-select>
</template>
<script>
// import { mapState } from 'vuex'
import Storage from '@/utils/storage'
// import { merchantCountry } from '@/api/api'

export default {
  name: 'Country',
  props: {
    value: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      // language: 'zh'
      allCountry: Storage.getUser()
    }
  },
  // computed: {
  //   ...mapState({
  //     allCountry: (state) => state.user.allCountry
  //   })
  // },
  methods: {
    change(val) {
      this.$emit('input', val)
    }
  }
}
</script>
