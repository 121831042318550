<template>
  <div class="submit">
    <img src="@/assets/images/time.jpg" alt="资料提交中，等待审核" />
    <p class="title">资料提交中，等待审核</p>
    <p>资料提交后，需1-2个工作日审核完毕，请您耐心等待</p>
    <router-link to="/merchant/list">
      <el-button>返回商户列表</el-button>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'Submit', //商户进件，完成提交
  data() {
    return {}
  }
}
</script>

<style lang="scss" scoped>
.submit {
  text-align: center;
  // 资料提交中，等待审核
  & > img {
    width: 54px;
    height: 54px;
    margin: 60px 0 20px;
  }
  & > p:nth-of-type(2) {
    margin: 20px 0 50px;
  }
  .title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
  }
}
</style>

