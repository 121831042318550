<template>
  <div>
    <el-form :model="form" :rules="rules" ref="ruleForm" class="form" label-width="124px" label-position="left">
      <p>商户补充文档</p>
      <p class="el-text">请提供金融监管机构所要求的开户文件 (pdf / jpeg / png)</p>
      <el-form-item :label="item.label" :prop="item.key" v-for="(item, index) in fileNodes" :key="index">
        <!-- logo,需要多传一个参数 -->
        <File :tip="item.tip" v-model="form[item.key]" />
      </el-form-item>
    </el-form>
    <p class="operation">
      <el-button @click="$parent.step = 'Information'">返回上一步</el-button>
      <el-button type="success" @click="scheduleFiles('ruleForm', 'draft')">保存草稿箱</el-button>
      <el-button type="primary" @click="scheduleFiles('ruleForm', 'save')">提交审核</el-button>
    </p>
  </div>
</template>

<script>
import { scheduleFiles, scheduleDone } from '@/api/api'
import File from '@/components/file'
import { MessageBox } from 'element-ui'

export default {
  name: 'Supplement', //商户进件，补充文档
  components: { File },
  data() {
    return {
      form: {
        certificate_file: [],
        director_file: [],
        shareholder_file: [],
        apply_in_file: [],
        logo_file_url: []
      },
      rules: {
        certificate_file: [{ required: true, message: '请上传公司注册证书', trigger: ['change', 'blur'] }],
        director_file: [{ required: true, message: '请上传公司董事身份证', trigger: ['change', 'blur'] }],
        shareholder_file: [{ required: true, message: '请上传股东身份证', trigger: ['change', 'blur'] }],
        apply_in_file: [{ required: true, message: '请上传商户准入申请表', trigger: ['change', 'blur'] }]
        // logo_file_url: [{ required: true, message: '请上传公司商标Logo', trigger: ['change', 'blur'] }]
      },
      fileNodes: [
        {
          label: '公司成立证明',
          tip: [
            '1. 商业登记证 副本 (BR)',
            '2. 公司注册证书 副本 (CI)',
            '3. 最近一或两个月内发出的银行月结单一份 或 开户文件，须与商户名称及商户收款账户号码一致'
          ],
          key: 'certificate_file'
        },
        {
          label: '公司董事身份证',
          tip: ['1. 公司董事身份证 副本 (ID)', '2. 独资经营提供拥有身份证件扫描件'],
          key: 'director_file'
        },
        { label: '股东身份证', tip: ['大于25%的个人股东身份证件扫描件'], key: 'shareholder_file' },
        { label: '商户准入申请表', tip: ['商户准入申请表'], key: 'apply_in_file' },
        {
          label: '公司商标Logo',
          tip: ['公司商标 Logo (商户后台用、客户付款时会展示此logo)'],
          key: 'logo_file_url'
        }
      ]
    }
  },
  methods: {
    // 提交文件
    scheduleFiles(formName, type) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let params = { ...this.form }
          Object.keys(params).forEach((key) => {
            const val = params[key]
            if (key === 'logo_file_url') {
              if (val.length >= 1) params.logo_file_url = val[0].file_url
            } else {
              val.forEach((item) => delete item.file_url)
              params[key] = JSON.stringify(val)
            }
          })
          scheduleFiles({ ...params, merchant_no: this.$route.query.merchant_no }).then((res) => {
            if (res.code === 200) {
              if (type === 'draft') {
                this.$router.push({ name: 'Drafts' })
              } else {
                this.scheduleDone()
              }
            }
          })
        } else {
          return false
        }
      })
    },
    // 提交审核
    scheduleDone() {
      const merchant_no = this.$route.query.merchant_no
      scheduleDone({ merchant_no }).then((res) => {
        if (res.data.need_fill_in_list) {
          MessageBox(`${res.data.need_fill_in_list}`, '提交失败，您有以下资料未提交', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          })
            .then(() => {})
            .catch(() => {})
        } else {
          this.$parent.step = 'Submit'
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.form {
  margin-top: 20px;
  border: 1px solid #f0f2f5;
  & > p:nth-of-type(1) {
    height: 46px;
    line-height: 46px;
    background: #f5f7fa;
    margin-bottom: 20px;
    padding-left: 20px;
  }
  .el-text {
    padding: 0 0 20px 60px;
    &::before {
      content: '*';
      color: #f56c6c;
      margin-right: 4px;
    }
  }
  .el-form-item {
    padding-left: 60px;
  }
}
.el-upload__tip {
  line-height: 1;
}
.operation {
  text-align: center;
  margin-top: 20px;
}
</style>



